import React, { Fragment, useEffect, useRef } from "react";

import "./shutter.scss";

export const Shutter = React.memo(function Shutter(props) {

    const shutter = useRef();

    const shutterTransitionEnd = () => {
        shutter.current.classList.remove('shutter_hide');
    }

    useEffect(() => {
        if (props.show === false) {
            shutter.current.classList.remove('shutter_hide');
            shutter.current.classList.add('shutter_show');
        } else {
            shutter.current.classList.remove('shutter_show');
            shutter.current.classList.add('shutter_hide');
            shutter.current.addEventListener('transitionend', shutterTransitionEnd);
        }
        
    }, [props.show]);
     
    return (
        <Fragment>
            
            {console.log('rerender Shutter')}

            <div className="shutter" ref={shutter}></div>
        </Fragment>
    )
});