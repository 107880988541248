import React from 'react';

import SVG from 'react-inlinesvg';
import spinnerSVG from '../../media/images/spinner/spinner.svg'

import './spinner.scss';

const Spinner = () => {
    return (
        <div className="spinner">
            <div className="spinner__wrapper">
                <div className="spinner__loaderBlock">
                    <SVG src={spinnerSVG} className="spinner__loading-animation__path"/>
                </div>
            </div>
        </div>
    )
}

export default Spinner;