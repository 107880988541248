export const SET_OFFSET_INDEX = 'SET_OFFSET_INDEX';
export const SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED = 'SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED';
export const SET_PAGE_TRANSITION = 'SET_PAGE_TRANSITION';
export const SET_SLIDER_BACK_ANIMATION = 'SET_SLIDER_BACK_ANIMATION';
export const SET_IMG_TRANSITION_ANIMATION = 'SET_IMG_TRANSITION_ANIMATION';
export const SET_FLIPPED = 'SET_FLIPPED';
export const SET_SLIDE_ANIMATION = 'SET_SLIDE_ANIMATION';
export const SET_SLIDES_ARR = 'SET_SLIDES_ARR';
export const SET_SLIDER_BLOCKER = 'SET_SLIDER_BLOCKER';
export const SET_MENU_BAR_SLIDER_ANIMATION = 'SET_MENU_BAR_SLIDER_ANIMATION';
export const SET_SLIDER_TRANSITION_DIRECTION = 'SET_SLIDER_TRANSITION_DIRECTION';
export const SET_HEADER_STATUS = 'SET_HEADER_STATUS';
export const SET_PREV_LOCATION = 'SET_PREV_LOCATION';
