import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setOffsetIndex, setPageTransition, setSlideAnimation, setSliderBlocker, setWheelOffsetIndexChangingBlocked } from '../store/actions/mainActions/mainActions';
import { useLocation } from 'react-router-dom';

const OffsetIndexChanger = React.memo(function OffsetIndexChanger(props) {

    const dispatch = useDispatch();

    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);
    const sliderTransitionDirection = useSelector((state) => state.mainReducer.sliderTransitionDirection, shallowEqual);
    const wheelOffsetIndexChangingBlocked = useSelector((state) => state.mainReducer.wheelOffsetIndexChangingBlocked, shallowEqual);
    const prevLocation = useSelector((state) => state.mainReducer.prevLocation, shallowEqual);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/' && prevLocation === '/portfolio') {
            dispatch(setOffsetIndex(1));
        }
    }, [location.pathname])

    useEffect(() => {
        if (!wheelOffsetIndexChangingBlocked) {
            if (sliderTransitionDirection === 'bottom') {
                dispatch(setOffsetIndex(offsetIndex + 1));
                dispatch(setSlideAnimation(true));
            } else if (sliderTransitionDirection === 'top') {
                dispatch(setOffsetIndex(offsetIndex - 1));
                dispatch(setSlideAnimation(true));
            }
        }
    }, [sliderTransitionDirection]);
});

export default OffsetIndexChanger;