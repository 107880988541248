import React from 'react';

import './error.scss';

const Error = (props) => {
    return (
        <div className="error">
            <h1 className="error__title neonText">
                Error
            </h1>
            <span className="error__text neonText">{props.errorMessage}</span> 
            <img className="error__img" src={require(`../../media/images/error/error.gif`)} alt="" />
        </div>
    )
}

export default Error;