import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual} from "react-redux";
import { setPageTransition, setOffsetIndex, setFlipped } from '../store/actions/mainActions/mainActions';
import { useCallback } from "react";
import { scrollTo } from "scroll-polyfill";


export const PageTransitionChanger = React.memo(function PageTransitionChanger() {

    const dispatch = useDispatch();
    const location = useLocation();

    const pageTransition = useSelector((state) => state.mainReducer.pageTransition, shallowEqual);

    const sliderBackAnimation = useSelector((state) => state.mainReducer.sliderBackAnimation, shallowEqual);
    const imgTransitionAnimation = useSelector((state) => state.mainReducer.imgTransitionAnimation, shallowEqual);
    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);

    const handleToggleStandardBehavior = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    
      function checkScrollCompletion() {
        if (window.scrollY === 0) {
            dispatch(setPageTransition('scroll-top--end'));
            window.removeEventListener("scroll", checkScrollCompletion);
        }
      }

    const handleEventsBlocker = (param) => {
        if (param === 'add') {
            document.addEventListener('wheel', handleToggleStandardBehavior, {passive: false});
            // document.addEventListener('touchstart', handleToggleStandardBehavior, {passive: false});
            // document.addEventListener('touchend', handleToggleStandardBehavior, {passive: false});
            document.addEventListener('touchmove', handleToggleStandardBehavior, {passive: false});
            // document.addEventListener('touchcancel', handleToggleStandardBehavior, {passive: false});
            // document.addEventListener('click', handleToggleStandardBehavior, {passive: false});
            document.addEventListener('mousedown', handleToggleStandardBehavior, {passive: false});
            document.addEventListener('mouseup', handleToggleStandardBehavior, {passive: false});
            // document.addEventListener('touchforcechange', handleToggleStandardBehavior, {passive: false});
        } else if ('remove') {
            document.removeEventListener('wheel', handleToggleStandardBehavior, {passive: false});
            // document.removeEventListener('touchstart', handleToggleStandardBehavior, {passive: false});
            // document.removeEventListener('touchend', handleToggleStandardBehavior, {passive: false});
            document.removeEventListener('touchmove', handleToggleStandardBehavior, {passive: false});
            // document.removeEventListener('touchcancel', handleToggleStandardBehavior, {passive: false});
            // document.removeEventListener('click', handleToggleStandardBehavior, {passive: false});
            document.removeEventListener('mousedown', handleToggleStandardBehavior, {passive: false});
            document.removeEventListener('mouseup', handleToggleStandardBehavior, {passive: false});
            // document.removeEventListener('touchforcechange', handleToggleStandardBehavior, {passive: false});
        }
    }

    useEffect(() => {
        if (pageTransition === 'goBack') {
            dispatch(setPageTransition(null));
        }
        handleEventsBlocker('add');
        setTimeout(() => {
            handleEventsBlocker('remove');
        });
    }, [location.pathname]);

    useEffect(() => {
        switch(pageTransition) {
            case 'forward':
                if (imgTransitionAnimation === 'img-forward-animation--end') {
                    dispatch(setPageTransition(null));
                }
                break;
            case 'back-arrow--clicked':
                if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/contactUs') {
                    handleEventsBlocker('add');
                    if (window.pageYOffset === 0) {
                        dispatch(setPageTransition('scroll-top--end'));
                    } else {
                        dispatch(setPageTransition('scroll-top--start'));
                    }
                } else if (location.pathname === '/' || location.pathname === '/portfolio') {
                    if (offsetIndex === 0) {
                        dispatch(setPageTransition('scroll-top--end'));
                    } else {
                        dispatch(setPageTransition('scroll-top--start'));
                    }
                } else if (location.pathname === '/contactUs') {
                    dispatch(setFlipped(false));
                    dispatch(setPageTransition('back-animation--start'));
                    setTimeout(() => {
                        dispatch(setPageTransition('goBack'));
                    }, 500);
                }
                break;
            case 'scroll-top--start':
                if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/contactUs') {
                    scrollTo(window, {
                        behavior: "smooth",
                        top: 0
                      });
                      window.addEventListener("scroll", checkScrollCompletion);

                } else if (location.pathname === '/' || location.pathname === '/portfolio') {
                    dispatch(setOffsetIndex(0));
                    if (sliderBackAnimation === 'slider-animation--end') {
                        dispatch(setPageTransition('scroll-top--end'));
                    }
                }
                break;
            case 'scroll-top--end':
                dispatch(setPageTransition('back-animation--start'));
                break;
            case 'back-animation--start':
                if (imgTransitionAnimation === 'img-back-animation--end') {
                    dispatch(setPageTransition('back-animation--end'));
                }
                break;
            case 'back-animation--end': 
                dispatch(setPageTransition('goBack'));
                break;
            case 'goBack':
                
                break;
            default:
                break;
        }
    }, [pageTransition, sliderBackAnimation, imgTransitionAnimation]);
});