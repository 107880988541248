import {
    SET_OFFSET_INDEX,
    SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED,
    SET_FLIPPED,
    SET_PAGE_TRANSITION,
    SET_SLIDER_BACK_ANIMATION,
    SET_IMG_TRANSITION_ANIMATION,
    SET_SLIDE_ANIMATION,
    SET_SLIDES_ARR,
    SET_SLIDER_BLOCKER,
    SET_MENU_BAR_SLIDER_ANIMATION,
    SET_SLIDER_TRANSITION_DIRECTION,
    SET_HEADER_STATUS,
    SET_PREV_LOCATION
} from '../../actions/mainActions/mainTypes';

const initialState = {
    offsetIndex: 0,
    wheelOffsetIndexChangingBlocked: false,
    slideAnimation: false,
    flipped: false,
    pageTransition: null,
    sliderBackAnimation: null,
    imgTransitionAnimation: null,
    slidesArr: [],
    sliderBlocker: false,
    menuBarSliderAnimation: false,
    sliderTransitionDirection: 'stopped',
    headerStatus: null,
    prevLocation: null
};

export const mainReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case  SET_OFFSET_INDEX:
            return {...state, offsetIndex: payload.offsetIndex};
        case SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED:
            return {...state, wheelOffsetIndexChangingBlocked: payload.wheelOffsetIndexChangingBlocked};
        case  SET_FLIPPED:
            return {...state, flipped: payload.flipped};
        case SET_PAGE_TRANSITION:
            return {...state, pageTransition: payload.pageTransition};
        case SET_SLIDER_BACK_ANIMATION:
            return {...state, sliderBackAnimation: payload.sliderBackAnimation};
        case SET_IMG_TRANSITION_ANIMATION:
            return {...state, imgTransitionAnimation: payload.imgTransitionAnimation};
        case SET_SLIDE_ANIMATION:
            return {...state, slideAnimation: payload.slideAnimation};
        case SET_SLIDES_ARR:
            return {...state, slidesArr: payload.slidesArr};
        case  SET_SLIDER_BLOCKER:
            return {...state, sliderBlocker: payload.sliderBlocker};
        case  SET_MENU_BAR_SLIDER_ANIMATION:
            return {...state, menuBarSliderAnimation: payload.menuBarSliderAnimation};
        case  SET_SLIDER_TRANSITION_DIRECTION:
            return {...state, sliderTransitionDirection: payload.sliderTransitionDirection};
        case  SET_HEADER_STATUS:
            return {...state, headerStatus: payload.headerStatus};
        case  SET_PREV_LOCATION:
            return {...state, prevLocation: payload.prevLocation};
        default:
            return state;
    }
};