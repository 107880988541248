import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { Transition } from 'react-transition-group';

import { MenuBarItem } from "../menuBarItem/menuBarItem";

import "./menuBarList.scss";

export const MenuBarList = React.memo( function MenuBarList() {
    
    const location = useLocation();
    const slidesArr = useSelector((state) => state.mainReducer.slidesArr, shallowEqual);

    const [menuBarVisible, setMenuBarVisible] = useState(false);

    const defaultStyles = {
        transform: 'translateX(0) translateY(0)'
    }
      
    const transitionStyles = {
        entering: { transform: 'translateX(0) translateY(0)', transition: 'transform 300ms ease-in-out' },
        entered: { transform: 'translateX(0) translateY(0)', transition: 'transform 300ms ease-in-out' },
        exiting: { transform: (window.innerWidth >= 1200) ? 'translateX(-10vw) translateY(0)' : 'translateX(0) translateY(10vh)', transition: 'transform 300ms ease-in-out' },
        exited: { transform: (window.innerWidth >= 1200) ? 'translateX(-10vw) translateY(0)' : 'translateX(0) translateY(10vh)', transition: 'transform 300ms ease-in-out' }
    }

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/portfolio') {
            setMenuBarVisible(true);
        } else {
            setMenuBarVisible(false);
        }
    }, [location.pathname]);
    
    return (
        <React.Fragment>

            {console.log('rerender MenuBarList')}
            
            <Transition
                in={menuBarVisible}
                timeout={500}
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <div
                        className="menu-bar"
                        style={{
                            ...defaultStyles,
                            ...transitionStyles[state],
                        }}
                    >
                        <ul className="menu-bar__list">
                            <MenuBarItem
                                key={0}
                                name={'top'}
                                index={0}
                            />
                            {
                                slidesArr.map((item, i) =>
                                    <MenuBarItem
                                        key={i + 1}
                                        name={item.name}
                                        index={i + 1}
                                />)
                            }
                        </ul>
                    </div>
                )}
            </Transition>

        </React.Fragment>
    )
});