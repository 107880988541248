import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink, Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setOffsetIndex, setWheelOffsetIndexChangingBlocked, setHeaderStatus, setPageTransition, setSlideAnimation, setFlipped, setMenuBarSliderAnimation, setSliderTransitionDirection, setSliderBlocker, setSliderBackAnimation, setImgTransitionAnimation, setPrevLocation } from '../../store/actions/mainActions/mainActions';
import SVG from 'react-inlinesvg';
import logo from "../../media/images/header/logo.svg";
import insta from "../../media/images/header/insta.svg";

import "./header.scss";

export const Header = React.memo(function Header() {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const headerNavRef = useRef(null);
    const headerBurgerMenuRef = useRef(null);
    const lastFocusableEl =  useRef(null);

    const headerStatus = useSelector((state) => state.mainReducer.headerStatus, shallowEqual);
    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);
    
    const [color, setColor] = useState('#fff');

    const onClickLogo = (e) => {
        // location.reload();
        if (headerStatus === 'show') {
            dispatch(setHeaderStatus('hide'));
        }
        if  (location.pathname !== '/') {
            dispatch(setOffsetIndex(0));
            dispatch(setSliderBlocker(false));
            dispatch(setWheelOffsetIndexChangingBlocked(false));
            dispatch(setSliderTransitionDirection('stopped'));
            dispatch(setPageTransition(null));
            dispatch(setSlideAnimation(false));
            dispatch(setFlipped(false));
            dispatch(setSliderBackAnimation(null));
            dispatch(setImgTransitionAnimation(null));
            dispatch(setMenuBarSliderAnimation(false));
        } else {
            if (offsetIndex > 0) {
                dispatch(setWheelOffsetIndexChangingBlocked(true));
                e.preventDefault();
                dispatch(setOffsetIndex(0));
                dispatch(setSlideAnimation(true));
                dispatch(setMenuBarSliderAnimation(true));
            }
            
            
        }
    };

    const onClickWriteToUs = (e) => {
        e.preventDefault();
        if (location.pathname === '/' && offsetIndex === 3) {
            dispatch(setPageTransition('forward'));
            dispatch(setOffsetIndex(0));
            navigate('/contactUs');
        } else {
            navigate('/contactUs');
        }
    };

    const onClickBurgerMenu = () => {
        dispatch(setHeaderStatus((headerStatus === null || headerStatus === 'hide') ? 'show' : 'hide'))
    };

    const handleSetActiveNavLink = ({isActive}) => {
        if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/about' && location.pathname !== '/contactUs') {
            return 'header__nav-link'
        } else {
            if (isActive) {
                return 'header__nav-link header__nav-link--active';
            } else {
                return 'header__nav-link';
            }
        }        
    };

    const onNavLinkTransition = (e) => {
        if (e.target.classList.contains('header__nav-link--active')) {
            e.preventDefault();
        } else {
            setColor('white');
            dispatch(setPrevLocation(null));
            window.scrollTo(0, 0);
            dispatch(setOffsetIndex(0));
            dispatch(setHeaderStatus('hide'));
            dispatch(setPageTransition(null))
        }
    }

    const handleHeaderToggeColor = useCallback(() => {
        const clientHeight = document.documentElement.clientHeight - (document.documentElement.clientHeight / 100 * 9);
        if (window.scrollY >= clientHeight) {
            setColor('#626262');
        } else if (window.scrollY < clientHeight) {
            setColor('#fff');
        }
    }, []);
      
    const trapFocus = useCallback((e) => {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);
        if (!isTabPressed) {
            return; 
        }
        if ( e.shiftKey ) /* shift + tab */ {
            if (document.activeElement === headerBurgerMenuRef.current) {
                lastFocusableEl.current.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl.current) {
                headerBurgerMenuRef.current.focus();
                e.preventDefault(); 
            }
        }
    }, []);

    useEffect(() => {
        if (headerStatus === 'show') {
            dispatch(setHeaderStatus('hide'));
        }
        window.addEventListener('scroll', handleHeaderToggeColor);
        return () => {
            window.removeEventListener('scroll', handleHeaderToggeColor);
        }
    },[location.pathname]);

    useEffect(() => {
        if (headerStatus === 'show') {
            document.addEventListener('keydown', trapFocus);
            window.removeEventListener('scroll', handleHeaderToggeColor);
            setColor('#fff');
            dispatch(setWheelOffsetIndexChangingBlocked(true));
            headerNavRef.current.classList.remove('header__nav_hide');
            headerNavRef.current.classList.add('header__nav_show');
            headerBurgerMenuRef.current.classList.add('header__burger-menu_active');
            document.querySelector('html').style.overflowY = 'hidden';
        } else if (headerStatus === 'hide') {
            document.querySelector('html').style.overflowY = 'visible';
            setTimeout(() => {
                dispatch(setWheelOffsetIndexChangingBlocked(false));
            }, 750);
            handleHeaderToggeColor();
            if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/contactUs') {
                window.addEventListener('scroll', handleHeaderToggeColor);
            }
            document.removeEventListener('keydown', trapFocus);
            headerNavRef.current.classList.remove('header__nav_show');
            headerNavRef.current.classList.add('header__nav_hide');
            headerBurgerMenuRef.current.classList.remove('header__burger-menu_active');
        }
    }, [headerStatus]);
     
    return (
        <React.Fragment>
            
            {console.log('rerender Header')}

            <header className="header">
                <Link
                    to={'/'}
                    className="header__logo"
                    onClick={onClickLogo}
                >
                    <span className="visually-hidden">Главная страница</span>
                    <SVG
                        src={logo}
                        style={{
                            fill: color
                        }}
                    />
                </Link>
                <div className="header__links">
                    {/* <a className="header__link-instagram" href="https://www.instagram.com">
                        <span className="visually-hidden">Мы в инстаграме</span>
                        <SVG
                        src={insta}
                        style={{
                            fill: color
                        }}
                        />
                    </a> */}
                    <Link
                        onClick={onClickWriteToUs}
                        to={'/contactUs'} aria-label='Связаться с нами'
                        className={'header__link-contactUs'}
                        style={{
                            color: color
                        }}
                    >
                        Написать
                    </Link>
                    <button
                        ref={headerBurgerMenuRef}
                        className={(color === '#626262' && headerStatus !== 'show') ? 'header__burger-menu header__burger-menu--black' : 'header__burger-menu'}
                        // className={'header__burger-menu'}
                        onClick={onClickBurgerMenu}
                    >
                        <span className="visually-hidden">{headerStatus ? 'Закрыть' : 'Главное меню'}</span>
                    </button>
                    <nav
                        ref={headerNavRef}
                        className='header__nav'
                    >
                        <ul className='header__nav-list'>
                            <li>
                                <NavLink
                                    onClick={onNavLinkTransition}
                                    className={handleSetActiveNavLink}
                                    to='/'
                                    tabIndex={headerStatus ? 0 : -1}
                                >
                                    Главная
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={onNavLinkTransition}
                                    className={handleSetActiveNavLink}
                                    to='/portfolio'
                                    tabIndex={headerStatus ? 0 : -1}
                                >
                                    Проекты
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={onNavLinkTransition}
                                    className={handleSetActiveNavLink}
                                    to='/about'
                                    tabIndex={headerStatus ? 0 : -1}
                                >
                                    О нас
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    ref={lastFocusableEl}
                                    onClick={onNavLinkTransition}
                                    className={handleSetActiveNavLink}
                                    to='/contactUs'
                                    tabIndex={headerStatus ? 0 : -1}
                                >
                                    Контакты
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </React.Fragment>
    )
});