import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setMenuBarSliderAnimation, setOffsetIndex, setSlideAnimation, setWheelOffsetIndexChangingBlocked} from '../../store/actions/mainActions/mainActions';

import "./menuBarItem.scss";

export const MenuBarItem = React.memo( function MenuBarItem(props) {

    const dispatch = useDispatch();

    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);

    const menuBarItemRef = useRef();

    function onClickMenuBarItem() {
        dispatch(setOffsetIndex(props.index));
        dispatch(setSlideAnimation(true));
        dispatch(setMenuBarSliderAnimation(true));
        dispatch(setWheelOffsetIndexChangingBlocked(true));
    }

    useEffect(() => {
        if (props.index === offsetIndex) {
            menuBarItemRef.current.classList.add('menu-bar__item_active');
        } else {
            if (menuBarItemRef.current.classList.contains('menu-bar__item_active')) {
                menuBarItemRef.current.classList.remove('menu-bar__item_active');
            }
        }
    }, [offsetIndex]);

    return (
        <React.Fragment>

            {console.log('rerender MenuBarItem')}
                
            <li
                ref={menuBarItemRef}
                className='menu-bar__item'
                onClick={onClickMenuBarItem}>
                <a href={`#${props.name}`} tabIndex='-1'>{props.name}</a>
            </li>

        </React.Fragment>
    )
});