import React from "react";

import Error from '../error/error';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        logErrorToMyService: ''
    };
    }
  
    static getDerivedStateFromError(error) {
      // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        console.log('error', error.message);
      // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
      this.setState({
        logErrorToMyService: error.message
      });
    }
  
    render() {
      if (this.state.hasError) {
        // Можно отрендерить запасной UI произвольного вида
        return <Error 
                    errorMessage={this.state.logErrorToMyService}
                />;
      }
  
      return this.props.children; 
    }
  }