import React, { lazy, Suspense, useState } from "react";
import { Routes, Route } from 'react-router-dom';
// import smoothscroll from 'smoothscroll-polyfill';

import icerebroData from './icerebroData/db.json';

import { SliderBlockers } from "./components/sliderBlockers";
import Spinner from './components/spinner/spinner';
import { Header } from './components/header/header';
import BackArrow  from "./components/backArrow/backArrow";
import { MenuBarList } from './components/menuBarList/menuBarList';
import ScrollDownText from './components/scrollDownText/scrollDownText';
import OffsetIndexChanger from "./components/offsetIndexChanger";
import { PageTransitionChanger } from "./components/pageTransitionChanger";
import SpinnerLoader from "./components/spinnerLoader";
import { Shutter } from "./components/shutter/shutter";
import { Sky } from './components/sky/sky';
import { RemoveTrailingSlash } from "./TrailingSlash";
import {PopstateActions} from "./components/popstateActions.js";

const WelcomePage = lazy(() => import('./components/welcomePage/welcomePage'));
const About = lazy(() => import('./components/about/about'));
const Portfolio = lazy(() => import('./components/portfolio/portfolio'));
const PortfolioPages = lazy(() => import('./components/portfolioPages/portfolioPages'));
const ContactUs = lazy(() => import('./components/contactUs/contactUs'));
const NotFoundPage = lazy(() => import('./components/notFoundPage/notFoundPage'));


const App = () => {

  // smoothscroll.polyfill();

  const [show, setShow] = useState(true);

  const icerebroDataArr = icerebroData;

  return (
    
    <React.Fragment>

      {console.log('rerender App')}
      <PopstateActions />
      <SliderBlockers />
      <PageTransitionChanger />
      <OffsetIndexChanger />
      <Header />
      <BackArrow />
      <MenuBarList />
      <ScrollDownText />
      <Sky />
      <Shutter show={show}/>
      <Suspense fallback={<SpinnerLoader show={show} setShow={setShow} />}>
      <RemoveTrailingSlash/>
        {
          show ?
          <Routes>
            <Route exact path="/"  element={<WelcomePage icerebroData={icerebroDataArr}/>} />
            <Route exact path="/portfolio" element={<Portfolio icerebroData={icerebroDataArr}/>} />
            <Route exact path="/portfolio/:id" element={<PortfolioPages icerebroData={icerebroDataArr} />} />
            <Route exact path="/about" element={<About/>} />
            <Route exact path="/contactUs" element={<ContactUs/>} />
            <Route exact path="*" element={<NotFoundPage/>} />
        </Routes>
        :
        <Spinner/>
        }

      </Suspense>
    </React.Fragment>
  );
}

export default App;