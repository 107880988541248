import React, {useEffect, useState} from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { Transition } from "react-transition-group";

import './scrollDownText.scss';

const ScrollDownText = React.memo(function ScrollDownText() {

    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);
    const location = useLocation();

    const [scrollDownTextVisible, setScrollDownTextVisible] = useState(false);

    const defaultStyles = {
        transform: 'translateY(0%) translateZ(0)'
    }

    const transitionStyles = {
        entering: { transform: 'translateY(0%) translateZ(0)' },
        entered: { transform: 'translateY(0%) translateZ(0)' },
        exiting: { transform: 'translateY(150%) translateZ(0)' },
        exited: { transform: 'translateY(150%) translateZ(0)' },
    }
    
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/portfolio') {
            if (offsetIndex) {
                setScrollDownTextVisible(false);
            } else {
                setScrollDownTextVisible(true);
            }
        } else {
            setScrollDownTextVisible(false);
        }
    }, [location.pathname, offsetIndex]);

    return (
        <React.Fragment>

            {console.log('rerender ScrollDown')}

            <Transition
                in={scrollDownTextVisible}
                timeout={500}
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <span 
                        className="scroll-down-text"
                        style={{
                            ...defaultStyles,
                            ...transitionStyles[state],
                        }}
                    >
                        прокрутить вниз
                    </span>
                )}
            </Transition>
        </React.Fragment>
    )
});

export default ScrollDownText;