import React, { useEffect } from "react";
import { useDispatch} from "react-redux";
import { setPageTransition, setWheelOffsetIndexChangingBlocked, setSlideAnimation, setSliderBlocker, setSliderBackAnimation, setImgTransitionAnimation, setMenuBarSliderAnimation, setSliderTransitionDirection } from '../store/actions/mainActions/mainActions';

export const PopstateActions = React.memo(function PopstateActions() {

    const dispatch = useDispatch();

    const popstateActions = () => {
        dispatch(setWheelOffsetIndexChangingBlocked(false));
        dispatch(setSliderBlocker(false));
        dispatch(setSlideAnimation(false));
        dispatch(setPageTransition(null));
        dispatch(setSliderBackAnimation(null));
        dispatch(setImgTransitionAnimation(null));
        dispatch(setMenuBarSliderAnimation(false));
        dispatch(setSliderTransitionDirection('stopped'));
    }

    useEffect(() => {
        window.addEventListener('popstate', popstateActions, {passive: false});
    });
});