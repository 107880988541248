import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setPageTransition, setOffsetIndex, setFlipped } from '../../store/actions/mainActions/mainActions';
import { Transition } from "react-transition-group";

import "./backArrow.scss";
import { useCallback } from "react";

const BackArrow = React.memo(function BackArrow() {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const pageTransition = useSelector((state) => state.mainReducer.pageTransition, shallowEqual);
    const headerStatus = useSelector((state) => state.mainReducer.headerStatus, shallowEqual);

    const [visible, setVisible] = useState(false);
    const [linkTo, setLinkTo] = useState('');
    const [clicked, setClicked] = useState(false);
    const [color, setColor] = useState('#fff');
    const [sideBtnType, setSideBtnType] = useState(null);
    const [forwardBlocker, setForwardBlocker] = useState(true);

    const transitionStyles = {
        entering: { transform: 'translateX(0)', transition: 'transform 300ms ease-in-out' },
        entered: { transform: null, transition: null },
        exiting: { transform: 'translateX(-20vw)', transition: 'transform 300ms ease-in-out' },
        exited: { transform: 'translateX(-20vw)', transition: 'transform 300ms ease-in-out' },
    }

    const onClickBackArrow = (e) => {
        e.preventDefault();
        setClicked(true);
    };

    const onClickSideMouseButton = (e) => {
        // e.preventDefault();
        if (e.button === 3) {
            e.preventDefault();
            setSideBtnType('back');
        } else if (e.button === 4) {
            e.preventDefault();
            setSideBtnType('forward');
        }
    };

    const handleBackArrowToggeColor = useCallback(() => {
        const clientHeight = document.documentElement.clientHeight - (document.documentElement.clientHeight / 100 * 16);
        if (window.scrollY >= clientHeight) {
            setColor('#626262');
        } else if (window.scrollY < clientHeight) {
            setColor('#fff');
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', onClickSideMouseButton);
        return () => document.removeEventListener('mouseup', onClickSideMouseButton);
    }, []);

    useEffect(() => {
        setClicked(false);
        setColor('#fff')
        if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/contactUs') {
            window.addEventListener('scroll', handleBackArrowToggeColor);
        }

        switch (location.pathname) {
            case '/':
                setVisible(false);
                setLinkTo('');
                break;
            case '/portfolio':
                setVisible(true);
                setLinkTo('/');
                break;
            case '/about':
                setVisible(true);
                setLinkTo('/');

                break;
            case '/contactUs':
                setVisible(true);
                setLinkTo('/');
                break;
            default:
                setVisible(true);
                setLinkTo('/portfolio');
                break;
        }
        setSideBtnType(null);
        return () => {
            window.removeEventListener('scroll', handleBackArrowToggeColor);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (pageTransition === 'goBack') {
            if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/about' && location.pathname !== '/contactUs') {
                navigate(linkTo);
            } else if (location.pathname === '/portfolio') {
                dispatch(setOffsetIndex(1));
                navigate(linkTo);
            } else if (location.pathname === '/about') {
                dispatch(setOffsetIndex(2));
                navigate(linkTo);
            } else if (location.pathname === '/contactUs') {
                dispatch(setOffsetIndex(3));
                navigate(linkTo);
            }
        }
    }, [pageTransition]);

    useEffect(() => {
        if (headerStatus === 'show') {
            window.removeEventListener('scroll', handleBackArrowToggeColor);
        } else {
            handleBackArrowToggeColor();
            if (location.pathname !== '/' && location.pathname !== '/portfolio' && location.pathname !== '/contactUs') {
                window.addEventListener('scroll', handleBackArrowToggeColor);
            }
        }
    }, [headerStatus]);

    useEffect(() => {
        if (clicked && visible) {
            dispatch(setPageTransition('back-arrow--clicked'));
        }
    }, [clicked, visible]);

    useEffect(() => {
        setSideBtnType(null);
        if (sideBtnType === 'back' && location.pathname !== '/' && pageTransition === null) {
            setClicked(true);
            setForwardBlocker(false);
        } else if (sideBtnType === 'forward' && !forwardBlocker) {
            navigate(-1);
            dispatch(setOffsetIndex(0));
            setForwardBlocker(true);
            dispatch(setPageTransition('forward'));
        }
    }, [sideBtnType]);

    return (
        <React.Fragment>

            {console.log('rerender BackArrow')}

            <Transition
                in={visible}
                timeout={500}
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <Link
                        onClick={onClickBackArrow}
                        className={'back-arrow'}
                        style={{
                            ...transitionStyles[state],
                            pointerEvents: clicked ? 'none' : 'auto'
                        }}
                        to={linkTo}
                    >
                        <span className="visually-hidden">Назад</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="34" viewBox="0 0 67 34">
                            <g className="back-arrow__g" fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" transform="translate(2 1)"><path strokeWidth="2" d="M0,15.5533333 L64,15.5533333"></path><polyline strokeWidth="2" points="15.556 0 0 15.556 15.556 31.111"></polyline></g>
                        </svg>
                    </Link>
                )}
            </Transition>

        </React.Fragment>
    )
});

export default BackArrow;