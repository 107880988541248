import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as ReactDOMClient from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import {store} from './store/store';

import ErrorBoundry from './components/error-boundry/error-boundry';

import App from './App';

import './index.scss';
import { StrictMode } from 'react';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

root.render (
  <Provider store={store}>
    <ErrorBoundry>
        <BrowserRouter>
            {/* <StrictMode> */}
              <App />
            {/* </StrictMode> */}
          </BrowserRouter>
    </ErrorBoundry>
  </Provider>
);

reportWebVitals();
