import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual} from "react-redux";
import { setWheelOffsetIndexChangingBlocked, setSliderBlocker } from '../store/actions/mainActions/mainActions';

export const SliderBlockers = React.memo(function SliderBlockers() {

    const dispatch = useDispatch();

    const pageTransition = useSelector((state) => state.mainReducer.pageTransition, shallowEqual);

    useEffect(() => {
        switch (pageTransition) {
            case null:
                dispatch(setSliderBlocker(false));
                setTimeout(() => {
                    dispatch(setWheelOffsetIndexChangingBlocked(false));
                });
                break;
            case 'forward':
                dispatch(setWheelOffsetIndexChangingBlocked(true));
                break;
            case 'back-arrow--clicked':
                dispatch(setWheelOffsetIndexChangingBlocked(true));
                break;
            case 'scrollTop--end':
                dispatch(setSliderBlocker(true));
                break;
            default:
                break;
        }
    }, [pageTransition]);
});