import React, { useEffect, useRef } from 'react';
import { animated } from 'react-spring';
import { useSelector,shallowEqual } from 'react-redux';
import { useSpring } from 'react-spring';

import './sky.scss';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { useState } from 'react';
import { setFlipped } from '../../store/actions/mainActions/mainActions';

export const Sky = React.memo(function Sky() {

    const offsetIndex = useSelector((state) => state.mainReducer.offsetIndex, shallowEqual);
    const pageTransition = useSelector((state) => state.mainReducer.pageTransition, shallowEqual);
    const flipped = useSelector((state) => state.mainReducer.flipped, shallowEqual);

    const bgBlockRef = useRef();
    const moonRef = useRef();
    const cloudBlockRef = useRef();
    const sky = useRef();

    const speedStars = -29; //stars
    const speedGlow = -10; //moon and circle
    const speedCloud1 = -14; //cloud top left
    const speedCloud2 = -18; //cloud top right
    const speedCloud3 = -9; //cloud bottom left
    const speedCloud4 = -7; //cloud bottom rigth
    const speedPortfolioText = -20; //portfolio-text

    const transStars = (x, y) => `translate3d(${x / speedStars}px,${y / speedStars}px,0)`;
    const transGlow = (x, y) => `translate3d(${x / speedGlow}px,${y / speedGlow}px,0)`;
    const transCloud1 = (x, y) => `translate3d(${x / speedCloud1}px,${y / speedCloud1}px,0)`;
    const transCloud2 = (x, y) => `translate3d(${x / speedCloud2}px,${y / speedCloud2}px,0)`;
    const transCloud3 = (x, y) => `translate3d(${x / speedCloud3}px,${y / speedCloud3}px,0)`;
    const transCloud4 = (x, y) => `translate3d(${x / speedCloud4}px,${y / speedCloud4}px,0)`;
    const transPortfolioText = (x, y) => `translate3d(${x / speedPortfolioText}px,${y / speedPortfolioText}px,0)`;

    const location = useLocation();
    const [visible, setVisible] = useState(true);
    const [coordsXY, setCoordsXY] = useSpring(() => ({ xy: [0, 0], config: { mass: 1, tension: 550, friction: 140 }}));

    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

    const transitionStyles = {
        entering: { opacity: 1, transition: 'opacity 200ms linear' },
        entered: { opacity: 1, transition: null },
        exiting: { opacity: 0, transition: 'opacity 200ms linear' },
        exited: { opacity: 0, transition: 'opacity 200ms linear' },
    }

    useEffect(() => {
        window.addEventListener('mousemove', ({ clientX: x, clientY: y }) => setCoordsXY({ xy: calc(x, y)}));
    }, []);

    useEffect(() => {
        if (location.pathname !== '/' && location.pathname !== '/contactUs') {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (visible && location.pathname === '/') {
            if (offsetIndex === 0) {
                cloudBlockRef.current.style.transition = 'transform 1s ease 0.25s';
                cloudBlockRef.current.style.transform = 'translateX(0vw) translateZ(0)';
                moonRef.current.style.transition = 'transform 0.75s ease 0.25s';
                moonRef.current.style.transform = 'translateX(0vw) translateZ(0)';
            } else {
                moonRef.current.style.transition = 'transform 1s ease 0s';
                moonRef.current.style.transform = 'translateX(100vw) translateZ(0)';
                cloudBlockRef.current.style.transition = 'transform 1s ease 0.1s';
                cloudBlockRef.current.style.transform = 'translateX(100vw) translateZ(0)';
            }
        }
    }, [offsetIndex, visible, location.pathname]);

    return (
        <React.Fragment>

            {console.log('rerender Sky')}

            <Transition
                in={visible}
                timeout={500}
                mountOnEnter
                unmountOnExit
            >
                {(state) => (

                    <div
                        className="sky"
                        ref={sky}
                        style={{
                            ...transitionStyles[state]
                        }}
                    >
                        <animated.div style={{ transform: coordsXY.xy.to(transStars) }} className='stars'>                                </animated.div>
  
                        <div
                            ref={bgBlockRef}
                            className="bg-block"
                        >
                            
                            <animated.div style={{ transform: coordsXY.xy.to(transGlow) }} className='glow'>
                                {
                                        location.pathname === '/'
                                        ?
                                            <div
                                                className='moon' 
                                                ref={moonRef}
                                                style={{
                                                    transition: null,
                                                    transform: 'translateX(100vw) translateZ(0)'
                                                }}
                                            ></div>
                                        :
                                            null
                                }
                            </animated.div>
                        </div>

                        
                        {
                            location.pathname === '/'
                                ?
                                    <div
                                        className="cloud-block"
                                        ref={cloudBlockRef}
                                        style={{
                                            transition: null,
                                            transform: 'translateX(100vw) translateZ(0)'
                                        }}
                                    >
                                        <div className="cloud-block-top">
                                            <animated.div style={{ transform: coordsXY.xy.to(transCloud1) }} className='cloud cloud1'/>
                                            <animated.div style={{ transform: coordsXY.xy.to(transCloud2) }} className='cloud cloud2'/>
                                        </div>
                                        <animated.span style={{ transform: coordsXY.xy.to(transPortfolioText) }} className='text-portfolio'>ПОРТФОЛИО</animated.span>
                                        <div className="cloud-block-bottom">
                                            <animated.div style={{ transform: coordsXY.xy.to(transCloud3) }} className='cloud cloud3'/>
                                            <animated.div style={{ transform: coordsXY.xy.to(transCloud4) }} className='cloud cloud4'/>
                                        </div>
                                    </div>
                                :
                                    null
                        }
                        
                    </div>
                )}
            </Transition>

        </React.Fragment>
    )
});