import {
    SET_OFFSET_INDEX,
    SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED,
    SET_PAGE_TRANSITION,
    SET_SLIDER_BACK_ANIMATION,
    SET_IMG_TRANSITION_ANIMATION,
    SET_FLIPPED,
    SET_SLIDE_ANIMATION,
    SET_SLIDES_ARR,
    SET_SLIDER_BLOCKER,
    SET_MENU_BAR_SLIDER_ANIMATION,
    SET_SLIDER_TRANSITION_DIRECTION,
    SET_HEADER_STATUS,
    SET_PREV_LOCATION


} from './mainTypes';

export const setOffsetIndex = (offsetIndex) => {
    return {
        type: SET_OFFSET_INDEX,
        payload: {
            offsetIndex
        }
    }
}

export const setWheelOffsetIndexChangingBlocked = (wheelOffsetIndexChangingBlocked) => {
    return {
        type: SET_WHEEL_OFFSETINDEX_CHANGING_BLOCKED,
        payload: {
            wheelOffsetIndexChangingBlocked
        }
    }
}

export const setPageTransition = (pageTransition) => {
    return {
        type: SET_PAGE_TRANSITION,
        payload: {
            pageTransition
        }
    }
}

export const setSliderBackAnimation = (sliderBackAnimation) => {
    return {
        type: SET_SLIDER_BACK_ANIMATION,
        payload: {
            sliderBackAnimation
        }
    }
}
export const setImgTransitionAnimation = (imgTransitionAnimation) => {
    return {
        type: SET_IMG_TRANSITION_ANIMATION,
        payload: {
            imgTransitionAnimation
        }
    }
}


export const setFlipped = (flipped) => {
    return {
        type: SET_FLIPPED,
        payload: {
            flipped
        }
    }
}

export const setSlideAnimation = (slideAnimation) => {
    return {
        type: SET_SLIDE_ANIMATION,
        payload: {
            slideAnimation
        }
    }
}

export const setSlidesArr = (slidesArr) => {
    return {
        type: SET_SLIDES_ARR,
        payload: {
            slidesArr
        }
    }
}

export const setSliderBlocker = (sliderBlocker) => {
    return {
        type: SET_SLIDER_BLOCKER,
        payload: {
            sliderBlocker
        }
    }
}

export const setMenuBarSliderAnimation = (menuBarSliderAnimation) => {
    return {
        type: SET_MENU_BAR_SLIDER_ANIMATION,
        payload: {
            menuBarSliderAnimation
        }
    }
}

export const setSliderTransitionDirection = (sliderTransitionDirection) => {
    return {
        type: SET_SLIDER_TRANSITION_DIRECTION,
        payload: {
            sliderTransitionDirection
        }
    }
}

export const setHeaderStatus = (headerStatus) => {
    return {
        type: SET_HEADER_STATUS,
        payload: {
            headerStatus
        }
    }
}
export const setPrevLocation = (prevLocation) => {
    return {
        type: SET_PREV_LOCATION,
        payload: {
            prevLocation
        }
    }
}